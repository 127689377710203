import * as color from 'color';

// Generates RFC4122 version 4 guid (128 bits)
// (From ADAL.js: https://github.com/AzureAD/azure-activedirectory-library-for-js/blob/dev/lib/adal.js)
export function Guid(): string {
  // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
  // pseudo-random numbers.
  // The algorithm is as follows:
  //     Set the two most significant bits (bits 6 and 7) of the
  //        clock_seq_hi_and_reserved to zero and one, respectively.
  //     Set the four most significant bits (bits 12 through 15) of the
  //        time_hi_and_version field to the 4-bit version number from
  //        Section 4.1.3. Version4
  //     Set all the other bits to randomly (or pseudo-randomly) chosen
  //     values.
  // UUID                   = time-low "-" time-mid "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
  // time-low               = 4hexOctet
  // time-mid               = 2hexOctet
  // time-high-and-version  = 2hexOctet
  // clock-seq-and-reserved = hexOctet:
  // clock-seq-low          = hexOctet
  // node                   = 6hexOctet
  // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
  // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
  // y values are 8, 9, A, B

  const guidHolder = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  const hex = '0123456789abcdef';
  let r = 0;
  let guidResponse = '';
  for (let i = 0; i < 36; i++) {
    if (guidHolder[i] !== '-' && guidHolder[i] !== '4') {
      // each x and y needs to be random
      r = (Math.random() * 16) | 0;
    }
    if (guidHolder[i] === 'x') {
      guidResponse += hex[r];
    } else if (guidHolder[i] === 'y') {
      // clock-seq-and-reserved first hex is filtered and remaining hex values are random
      r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
      r |= 0x8; // set pos 3 to 1 as 1???
      guidResponse += hex[r];
    } else {
      guidResponse += guidHolder[i];
    }
  }

  return guidResponse;
}

// Helper function to generate color from a string (e.g., email)
// export const StringToColor = (str: string) => {
//   let hash = 0;
//   for (let i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash); // Existing hash computation
//   }

//   // Introduce more variability in the hash by adding another layer of shifting
//   hash = (hash & 0xfffffff) ^ (hash << 16) ^ (hash >> 8);

//   const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${(
//     (hash >> 16) &
//     0xff
//   )
//     .toString(16)
//     .padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;

//   return color;
// };

export const StringToColor = (text: string): string => {
  // Use a simple hash function to get a seed number
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate a random, bright color based on the seed
  const randomColor = color.rgb(Math.abs(hash) % 256, Math.abs(hash * 13) % 256, Math.abs(hash * 29) % 256);
  const brightColor = randomColor.lighten(0.5).saturate(0.5);

  return brightColor.hex();
}

export const getFilterValueDisplay = (filterValue: any): string => {
  if (filterValue === undefined || filterValue === null) return "";
  if (Object.prototype.hasOwnProperty.call(filterValue, "name"))
    return filterValue.name;
  return String(filterValue);
}

export const toPascalCase = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) return obj;
  if (Array.isArray(obj)) return obj;
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.charAt(0).toUpperCase() + key.slice(1),
      value
    ])
  );
};

export const toPascalCaseRecursively = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) return obj;
  if (Array.isArray(obj)) return obj.map(toPascalCase);
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.charAt(0).toUpperCase() + key.slice(1),
      toPascalCase(value)
    ])
  );
};