const config = {
  teamsUrl: 'https://teams.cloud.microsoft',
  initiateLoginEndpoint: `${process.env.REACT_APP_TEAMS_APPURL}/auth-start`,
  clientId: process.env.REACT_APP_CLIENTID,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  graphEndpint: 'https://graph.microsoft.com',
  adminLoginUrl: process.env.REACT_APP_ADMIN_LOGINURL,
  teamsAppUrl: process.env.REACT_APP_TEAMS_APPURL,
  scopes: ['https://graph.microsoft.com/.default'],
  defaultScopes: [],
  easyUserScopes: ['Presence.Read.All', 'User.Read.All'],
};

export default config;