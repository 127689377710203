import {
  EuiButton,
  EuiFlexGroup,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { Link, MessageBar, MessageBarBody, MessageBarTitle, Toast, ToastBody, ToastTitle } from '@fluentui/react-components';
import { Guid } from 'guid-typescript';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SharedMailbox, UserConfiguration, UserConfigWithVariants } from '../../common/interfaces';
import { EasyContext } from '../../components/EasyContext';
import AddMailboxModal from '../../components/modals/AddMailboxModal';
import SharedMailboxTable from '../../components/tables/SharedMailboxTable';
import { useToast } from '../../contexts/ToastContext';
import { ConfigurationService } from '../../services/ConfigurationService';
import { toPascalCase } from '../../common/helpers';

export const SharedMailboxSection: FC = () => {
  const { t } = useTranslation();
  const { accessToken, userId, userConfig, dispatch, tenantConfig } =
    useContext(EasyContext);
  const { dispatchToast } = useToast();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [mailboxAddress, setMailboxAddress] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [hasReachedMax, setHasReachedMax] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [sharedMailboxes, setSharedMailboxes] = useState<SharedMailbox[]>([]);

  const configurationService = new ConfigurationService(accessToken);

  useEffect(() => {
    // For an unknown reason, the keys of UserConfiguration gets loercased 
    const config: UserConfiguration = toPascalCase(userConfig);
    if (config) {
      setSharedMailboxes(config.SharedMailboxes || []);
    }
  }, []);

  useEffect(() => {
    setHasReachedMax(sharedMailboxes.length >= 5);
  }, [sharedMailboxes]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMailboxAddress(value);
    setIsValidEmail(validateEmail(value));
    const allMails: SharedMailbox[] = [...tenantConfig?.sharedMailboxes ?? [], ...sharedMailboxes];
    setHasDuplicates(allMails.some(
      mailbox => mailbox.label === value || mailbox.address === value
    ));
    setShowErrors(false);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setShowErrors(false);
    setMailboxAddress('');
    setIsValidEmail(false);
  };

  const handleDelete = async (mailbox: string) => {
    if (mailbox) {
      try {
        const result = await configurationService.deleteSharedMailbox(
          userId,
          tenantConfig!.TenantId,
          mailbox,
        );
        if (result) {
          showToast('remove', mailbox);
          const config = toPascalCase(result);
          dispatch({ type: 'SET_USER_CONFIG', payload: config });
          setSharedMailboxes(config.SharedMailboxes || []);
        } else {
          showToast('error');
        }
      } finally {
        setShowErrors(false);
      }
    }
  };

  const showToast = (action: 'add' | 'remove' | 'error', label?: string) => {
    if (action !== 'error') {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {action === 'add'
              ? t('Notifications.Titles.SharedMailbox.Added')
              : t('Notifications.Titles.SharedMailbox.Deleted')}
          </ToastTitle>
          <ToastBody>
            {action === 'add'
              ? t('Notifications.Contents.SharedMailbox.Added', { label })
              : t('Notifications.Contents.SharedMailbox.Deleted', { label })}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'success' },
      );
    } else {
      dispatchToast(
        <Toast>
          <ToastTitle>{t('Notifications.Titles.Error')}</ToastTitle>
          <ToastBody>{t('Notifications.Contents.Error')}</ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'error' },
      );
    }
  };

  const handleAdd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      const newMailbox = {
        id: Guid.create().toString(),
        label: mailboxAddress,
        address: mailboxAddress,
      };

      const result = await configurationService.postSharedMailbox(
        userId,
        tenantConfig!.TenantId,
        newMailbox,
      );
      if (result) {
        dispatch({ type: 'SET_USER_CONFIG', payload: result });
        setSharedMailboxes([...sharedMailboxes, newMailbox]);
        showToast('add', newMailbox.address);
        closeModal();
      }
    } catch (error) {
      showToast('error', error as string);
      console.error('Error adding mailbox:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="s">
        <h2>{t('Configuration.SharedMailboxes.Title')} ( {sharedMailboxes.length} / 5 )</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>{t('Configuration.SharedMailboxes.Text')}</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiFlexGroup gutterSize="s" alignItems="flexStart" wrap>
        {hasReachedMax ?
          <MessageBar key="warning" intent="warning">
            <MessageBarBody>
              {t('Configuration.SharedMailboxes.MaxCountReached')}
            </MessageBarBody>
          </MessageBar>
          : <EuiButton
            iconType="plusInCircle"
            size="s"
            color="primary"
            onClick={() => setIsModalVisible(true)}
            fill
          >
            {t('Configuration.SharedMailboxes.AddButton')}
          </EuiButton>}
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <SharedMailboxTable
        mailboxes={sharedMailboxes}
        onDeleteMailbox={handleDelete}
      />

      {isModalVisible && (
        <AddMailboxModal
          isVisible={isModalVisible}
          isLoading={isLoading}
          showErrors={showErrors}
          mailboxAddress={mailboxAddress}
          isValidEmail={isValidEmail}
          onClose={closeModal}
          onSubmit={handleAdd}
          onChange={handleEmailChange}
          hasDublicates={hasDuplicates}
        />
      )}
    </EuiPanel>
  );
};
