import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from '@elastic/eui';
import {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PersonalView} from '../common/interfaces';
import EasySaveViewModal from './modals/EasySaveViewModal';
import UpdateViewModal from './modals/UpdateViewModal';

interface SaveViewPopoverProps {
  selectedView: PersonalView | undefined;
  hasUnsavedChanges: boolean;
  isDisabled: boolean;
  onSaveNew: (viewName: string) => Promise<void>;
  onUpdate: () => Promise<void>;
  wasSearched: boolean;
}

export const SaveViewPopover: React.FC<SaveViewPopoverProps> = memo(
  ({
    selectedView,
    hasUnsavedChanges,
    isDisabled,
    onSaveNew,
    onUpdate,
    wasSearched,
  }) => {
    const {t} = useTranslation();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [showSaveNewModal, setShowSaveNewModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const closePopover = () => setIsPopoverOpen(false);

    return (
      <>
        <EuiPopover
          button={
            <EuiButtonEmpty
              iconType="save"
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              isDisabled={isDisabled}
            />
          }
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="s"
          anchorPosition="downLeft"
        >
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <EuiButton
                onClick={() => {
                  setShowSaveNewModal(true);
                  closePopover();
                }}
                iconType="save"
                disabled={!wasSearched}
                fullWidth
              >
                {t('Button.SaveView')}
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton
                onClick={() => {
                  setShowUpdateModal(true);
                  closePopover();
                }}
                iconType="refresh"
                color="primary"
                fullWidth
                disabled={!selectedView || !hasUnsavedChanges}
              >
                {t('Button.UpdateView')}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPopover>

        {showSaveNewModal && (
          <EasySaveViewModal
            onCloseModal={() => setShowSaveNewModal(false)}
            onSave={async (viewName) => {
              await onSaveNew(viewName);
              setShowSaveNewModal(false);
            }}
          />
        )}

        {showUpdateModal && (
          <UpdateViewModal
            onClose={() => setShowUpdateModal(false)}
            onUpdate={async () => {
              await onUpdate();
              setShowUpdateModal(false);
            }}
          />
        )}
      </>
    );
  },
);
