import {EuiFlexGroup, EuiFlexItem} from '@elastic/eui';
import {ResultViewProps} from '@elastic/react-search-ui-views';
import {SearchResult} from '@elastic/search-ui';
import {Button, Tooltip} from '@fluentui/react-components';
import {
  CallRegular,
  ChatRegular,
  ContactCardRegular,
  VideoRegular,
} from '@fluentui/react-icons';
import {useTranslation} from 'react-i18next';

import {DeepLinkActionType} from '../common/enums';
import {deepLinkHandler} from '../handlers/deepLinkHandler';

interface QuickActionsProps {
  resultView: ResultViewProps;
  handleDetailModalOpen: (result: SearchResult) => void;
  isInDetailsView?: boolean;
}

export const QuickActions: React.FC<QuickActionsProps> = ({
  resultView,
  handleDetailModalOpen,
  isInDetailsView = false,
}) => {
  const {t} = useTranslation();
  const mail = resultView.result.mails?.raw?.[0];

  return (
    <EuiFlexGroup gutterSize="m" responsive={false}>
      <EuiFlexItem key="start-chat" grow={false}>
        <Tooltip content={t('Main.Card.StartChat')} relationship="description">
          <Button
            appearance="transparent"
            size="small"
            icon={<ChatRegular />}
            onClick={() => deepLinkHandler(mail, DeepLinkActionType.TeamsChat)}
            disabled={!mail}
          />
        </Tooltip>
      </EuiFlexItem>
      <EuiFlexItem key="start-video-call" grow={false}>
        <Tooltip
          content={t('Main.Card.StartVideoCall')}
          relationship="description"
        >
          <Button
            appearance="transparent"
            size="small"
            icon={<VideoRegular />}
            onClick={() =>
              deepLinkHandler(mail, DeepLinkActionType.TeamsVideoCall)
            }
            disabled={!mail}
          />
        </Tooltip>
      </EuiFlexItem>
      <EuiFlexItem key="start-call" grow={false}>
        <Tooltip content={t('Main.Card.StartCall')} relationship="description">
          <Button
            appearance="transparent"
            size="small"
            icon={<CallRegular />}
            onClick={() => deepLinkHandler(mail, DeepLinkActionType.TeamsCall)}
            disabled={!mail}
          />
        </Tooltip>
      </EuiFlexItem>
      {!isInDetailsView && (
        <EuiFlexItem key="show-details" grow={false}>
          <Tooltip
            content={t('Main.Card.ShowDetails')}
            relationship="description"
          >
            <Button
              appearance="transparent"
              size="small"
              icon={<ContactCardRegular />}
              onClick={() => handleDetailModalOpen(resultView.result)}
            />
          </Tooltip>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
