import {EuiSpacer, EuiTitle} from '@elastic/eui';
import React from 'react';

import {EasyDirectoryUser, UserConfiguration} from '../../common/interfaces';
import {EasyUser} from '../EasyUser';
import {FavoriteStar} from '../FavoriteStar';

interface EasyFavoriteViewProps {
  user: EasyDirectoryUser;
  config: UserConfiguration;
  onFavoritesToggle: () => void;
}

export const EasyFavoriteView: React.FC<EasyFavoriteViewProps> = ({
  user,
  config,
  onFavoritesToggle,
}) => {
  const {display_name, id, type} = user;

  return user ? (
    <li className={'sui-result ' + type + ' presenceView'}>
      <div className="sui-result__header">
        <EasyUser userId={id} displayName={display_name} />
        <EuiTitle size="xs">
          <h1>{display_name}</h1>
        </EuiTitle>
        <FavoriteStar contactId={id} onFavoritesToggle={onFavoritesToggle} />
      </div>
      <div className="sui-result__body">
        <EuiSpacer size="m" />
      </div>
    </li>
  ) : null;
};
