import {
  Button,
  Input,
  makeStyles,
  MenuItem,
  MenuList,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
  tokens,
} from '@fluentui/react-components';
import {ChevronDown24Regular, Search24Regular} from '@fluentui/react-icons';
import React, {useCallback, useMemo, useState} from 'react';

const useStyles = makeStyles({
  popover: {
    minWidth: '200px',
    maxHeight: '250px',
    overflow: 'auto',
  },
  searchContainer: {
    padding: '8px',
    borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
  },
  menuItem: {
    cursor: 'pointer',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  noResults: {
    padding: '8px 16px',
    color: tokens.colorNeutralForeground2,
  },
  selectedItem: {
    backgroundColor: tokens.colorNeutralBackground1Selected,
  },
  button: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
});

interface TagOption {
  id: string;
  label: string;
}

interface SearchableTagDropdownProps {
  options: TagOption[];
  placeholder?: string;
  noResultsText?: string;
  onSelect: (option: TagOption) => void;
  selectedOption?: TagOption | null;
}

const SearchableTagDropdown: React.FC<SearchableTagDropdownProps> = ({
  options,
  placeholder = 'Select a tag',
  noResultsText = 'No matches found',
  onSelect,
  selectedOption,
}) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [options, searchValue]);

  const handleSelect = useCallback(
    (option: TagOption) => {
      onSelect(option);
      setIsOpen(false);
      setSearchValue('');
    },
    [onSelect],
  );

  const togglePopover = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchValue('');
    }
  }, [isOpen]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    [],
  );

  return (
    <Popover
      open={isOpen}
      onOpenChange={(e, data) => setIsOpen(data.open)}
      positioning={{
        position: 'below',
        align: 'start',
        offset: {mainAxis: 4},
        flipBoundary: null,
      }}
      trapFocus={true}
    >
      <PopoverTrigger>
        <Button
          className={styles.button}
          appearance="outline"
          onClick={togglePopover}
        >
          <Text>{selectedOption ? selectedOption.label : placeholder}</Text>
          <ChevronDown24Regular />
        </Button>
      </PopoverTrigger>

      <PopoverSurface className={styles.popover}>
        <div className={styles.searchContainer}>
          <Input
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search tags..."
            contentBefore={<Search24Regular />}
            autoFocus
          />
        </div>

        <MenuList>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <MenuItem key={option.id} onClick={() => handleSelect(option)}>
                <span
                  className={`${styles.menuItem} ${
                    selectedOption?.id === option.id ? styles.selectedItem : ''
                  }`}
                >
                  {option.label}
                </span>
              </MenuItem>
            ))
          ) : (
            <Text className={styles.noResults}>{noResultsText}</Text>
          )}
        </MenuList>
      </PopoverSurface>
    </Popover>
  );
};

export default SearchableTagDropdown;
