import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useEuiTheme,
} from '@elastic/eui';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PersonalView} from '../../common/interfaces';

type EditViewModalProps = {
  view: PersonalView;
  onClose: () => void;
  onSave: (updatedLabel: string) => Promise<void>;
};

const EditViewModal: React.FC<EditViewModalProps> = ({
  view,
  onClose,
  onSave,
}) => {
  const {t} = useTranslation();
  const [editViewName, setEditViewName] = useState(view.label);
  const [isSaving, setIsSaving] = useState(false);
  const { colorMode } = useEuiTheme();

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(editViewName);
      // Modal will be closed by the parent component after successful save
    } catch (error) {
      console.error('Error saving view:', error);
      // Optionally, display an error message to the user
      setIsSaving(false);
    }
  };

  return (
    <EuiModal onClose={onClose} initialFocus="[name=editViewName]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>{t('Modals.EditView.Title')}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody  className={colorMode.toLowerCase()}>
        <EuiForm>
          <EuiFormRow label={t('Modals.EditView.Text')} fullWidth>
            <EuiFieldText
              name="editViewName"
              value={editViewName}
              onChange={(e) => setEditViewName(e.target.value)}
              disabled={isSaving}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} isDisabled={isSaving}>
          {t('Modals.EditView.Cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={handleSave} fill isLoading={isSaving}>
          {t('Modals.EditView.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditViewModal;
