import React from 'react';

import {EasyPageOptionsProvider} from './EasyPageOptionsContext';
import {EasySearchProvider} from './EasySearchContext';

/**
 * Higher-Order Component to wrap a given component with the EasySearchProvider.
 * This ensures the wrapped component and its children have access to the EasySearch context.
 *
 * @param Component - The component to be wrapped with EasySearchProvider.
 * @returns A new component wrapped with EasySearchProvider.
 */
const withEasySearchProvider =
  (Component: React.ComponentType) => (props: any) => {
    console.log('withEasySearchProvider', props);
    return (
      <EasySearchProvider>
        <EasyPageOptionsProvider>
          <Component {...props} />
        </EasyPageOptionsProvider>
      </EasySearchProvider>
    );
  };

export default withEasySearchProvider;
