import {EuiSpacer} from '@elastic/eui';
import {memo} from 'react';

import {SharedMailboxSection} from './SharedMailboxSection';
import {TagManagerSection} from './TagManagerSection';

export const Settings: React.FC = memo(() => {
  return (
    <div className="tcf-configuration">
      <SharedMailboxSection />
      <EuiSpacer size="xxl" />
      <TagManagerSection />
      <EuiSpacer size="xxl" />
    </div>
  );
});
