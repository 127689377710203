import { Tooltip } from "@fluentui/react-components";
import { getFilterValueDisplay, StringToColor } from "../../common/helpers";

const EasyCustomFacetView = (props: any) => {
    const { options, onSelect, onRemove, field, label, placeholder, t, values, onMoreClick, showMore, showSearch, onSearch, searchPlaceholder } = props;

    return (
        <fieldset className="sui-facet">
            <legend className="sui-facet__title">{label}</legend>

            {showSearch && (
                <div className="sui-facet-search">
                    <input
                        className="sui-facet-search__text-input"
                        type="search"
                        placeholder={placeholder}
                        onChange={(e) => {
                            onSearch(e.target.value);
                        }}
                    />
                </div>
            )}
            <div className="sui-multi-checkbox-facet">
                {options.map((option: any) => {
                    const isSelected = Array.isArray(values)
                        ? values.includes(option.value)
                        : option.selected;

                    return (
                        <label
                            key={`${getFilterValueDisplay(option.value)}`}
                            className="sui-multi-checkbox-facet__option-label"
                        >
                            <div className="sui-multi-checkbox-facet__option-input-wrapper">
                                <input
                                    type="checkbox"
                                    className="sui-multi-checkbox-facet__checkbox"
                                    checked={isSelected}
                                    onChange={() => {
                                        if (isSelected) {
                                            onRemove(option.value);
                                        } else {
                                            onSelect(option.value);
                                        }
                                    }}
                                />
                                {option.value.length >= 23 ? (
                                    <Tooltip
                                        content={
                                            <span>
                                                {option.value === 'entraid'
                                                    ? t("Sidenav.EntraID")
                                                    : option.value === 'personal'
                                                        ? t("Sidenav.Personal")
                                                        : option.value}
                                            </span>
                                        }
                                        relationship={'description'}
                                    >
                                        <span className="sui-multi-checkbox-facet__input-text">
                                            {field === "type" && (
                                                <span
                                                    className="dot"
                                                    style={{ 
                                                        backgroundColor: `${option.value === 'entraid'
                                                            ? '#6264A7'
                                                            : option.value === 'personal'
                                                                ? '#ffa500'
                                                                : StringToColor(option.value)
                                                            }`
                                                    }}
                                                />
                                            )}
                                            {option.value === 'entraid'
                                                ? t("Sidenav.EntraID")
                                                : option.value === 'personal'
                                                    ? t("Sidenav.Personal")
                                                    : option.value}
                                            {option.value === "" && t("Sidenav.Unknown")}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span className="sui-multi-checkbox-facet__input-text">
                                        {field === "type" && (
                                            <span
                                                className="dot"
                                                style={{ 
                                                    backgroundColor: `${option.value === 'entraid'
                                                        ? '#6264A7'
                                                        : option.value === 'personal'
                                                            ? '#ffa500'
                                                            : StringToColor(option.value)
                                                        }`
                                                }}
                                            />
                                        )}
                                        {field === "type" && option.value === 'entraid'
                                            ? t("Sidenav.EntraID")
                                            : option.value === 'personal'
                                                ? t("Sidenav.Personal")
                                                : option.value}
                                        {option.value === "" && t("Sidenav.Unknown")}
                                    </span>
                                )}
                            </div>
                            <span className="sui-multi-checkbox-facet__option-count">
                                {option.count}
                            </span>
                        </label>
                    );
                })}
            </div>

            {showMore && (
                <button
                    type="button"
                    className="sui-facet-view-more"
                    onClick={onMoreClick}
                    aria-label="Show more options"
                >
                    + More
                </button>
            )}
        </fieldset>
    );
};

export default EasyCustomFacetView