import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPopover,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageOptions, UserConfiguration } from '../common/interfaces';
import { useEasyPageOptionsProvider } from '../contexts/EasyPageOptionsContext';
import { useEasySearchProvider } from '../contexts/EasySearchContext';
import { TeamsUserCredentialContext } from '../auth/singletonContext';
import { ConfigurationService } from '../services/ConfigurationService';
import { EasyContext } from './EasyContext';
import { useToast } from '../contexts/ToastContext';
import { Toast, ToastBody, ToastTitle } from '@fluentui/react-components';
import { toPascalCase } from '../common/helpers';
import { applyFilters } from './views/EasyCustomView';
import { FilterType, FilterValue } from '@elastic/search-ui';
import { withSearch } from '@elastic/react-search-ui';

const credential = TeamsUserCredentialContext.getInstance().getCredential();

type EasyOptionsMenuProps = {
  addFilter: (name: string, value: FilterValue, type?: FilterType) => void;
  removeFilter: (name: string, value?: FilterValue, type?: FilterType) => void;
};

const OptionsMenuComponent: React.FC<EasyOptionsMenuProps> = ({
  addFilter,
  removeFilter,
}) => {
  const { t } = useTranslation();
  const { dispatchToast } = useToast();
  const [isPopoverOpen, setPopover] = useState(false);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const { pageOptions, setPageOptions } = useEasyPageOptionsProvider();
  const { selectedView, setSelectedView } = useEasySearchProvider();

  const { accessToken, userConfig, userId } = useContext(EasyContext);

  const configurationService = new ConfigurationService(accessToken);
  const smallContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'smallContextMenuPopover',
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const handlePageViewOptionChange = (newPageOptions: PageOptions) => {
    setPageOptions(newPageOptions);
    if(pageOptions.presenceView === true) {
      removeFilter('type');
      addFilter('type', 'entraid', 'any');
    }
    
    if (selectedView) {
      setSelectedView({
        ...selectedView,
        pageOptions: newPageOptions,
      });
    }
  };

  useEffect(() => {
    if(pageOptions.presenceView === true) {
      removeFilter('type');
      addFilter('type', 'entraid', 'any');
    }
  }, [selectedView])

  const showToast = (
    success: boolean,
  ) => {
    if (success) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('Notifications.Titles.View.Synched.Synchronized')}
          </ToastTitle>
          <ToastBody>
            {t('Notifications.Contents.View.Synched.Synchronized')}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'success' },
      );
    } else {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('Notifications.Titles.View.Synched.Error')}
          </ToastTitle>
          <ToastBody>
            {t('Notifications.Contents.View.Synched.Error')}
          </ToastBody>
        </Toast>,
        { timeout: 3000, intent: 'error' },
      );
    }
  };

  const handleSyncContacts = () => {
    setIsSynchronizing(true);
    credential
      .getToken(["User.Read", "Contacts.ReadWrite"])
      .then(async (token) => {
        const config: UserConfiguration = toPascalCase(userConfig);
        if (token && config?.SynchedContacts) {
          const result = await configurationService.postSynchedUser(userId, config?.SynchedContacts, token);
          if (result) {
            showToast(true);
          } else {
            showToast(false);
          }
        }
        setIsSynchronizing(false);
      })
      .catch((e) => {
        showToast(false);
        setIsSynchronizing(false);
        console.error('Error adding synched contact:', e);
      });
  };

  const panels = [
    {
      id: 0,
      title: t('Main.Options.Name'),
      items: [
        { name: t('Main.Options.GroupBy'), icon: 'folderOpen', panel: 1 },
        {
          name: pageOptions.presenceView
            ? t('Main.Options.EnableStandardView')
            : t('Main.Options.EnablePresenceView'),
          icon: `${pageOptions.presenceView ? 'user' : 'timeRefresh'}`,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              presenceView: !pageOptions.presenceView,
            });
          },
        },
        {
          name: pageOptions.fullScreen
            ? t('Main.Options.DisableFullscreenResult')
            : t('Main.Options.EnableFullscreenResult'),
          icon: `${pageOptions.fullScreen ? 'check' : 'fullScreen'}`,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              fullScreen: !pageOptions.fullScreen,
            });
          },
        },
        {
          name: t('Main.Options.SyncContacts'),
          icon: isSynchronizing ? (<EuiLoadingSpinner size="m" />) : 'refresh',
          onClick: handleSyncContacts,
          disabled: false,
        },
        {
          name: t('Main.Options.BulkTagging'),
          icon: 'share',
          onClick: closePopover,
          disabled: true,
        },
        {
          name: t('Main.Options.RemoveGrouping'),
          icon: <EuiIcon type="trash" color="red" />,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: undefined,
            });
          },
          disabled: !pageOptions.groupedBy?.value,
        },
      ],
    },
    {
      id: 1,
      title: t('Main.Options.GroupBy'),
      items: [
        {
          name: t('Main.Options.Group.Tags'),
          icon: 'tag',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'tags',
                value: 'tags',
              },
            });
          },
        },
        {
          name: t('Main.Options.Group.Company'),
          icon: 'home',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'company_name',
                value: 'company_name',
              },
            });
          },
        },
        {
          name: t('Main.Options.Group.Department'),
          icon: 'users',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'department',
                value: 'department',
              },
            });
          },
        },
      ],
    },
  ];

  const button = (
    <EuiButtonEmpty
      iconType="arrowDown"
      iconSide="right"
      onClick={onButtonClick}
    >
      <EuiIcon type="controlsHorizontal" /> {t('Main.Options.Name')}
    </EuiButtonEmpty>
  );

  return (
    <EuiPopover
      id={smallContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downCenter"
    >
      <EuiContextMenu initialPanelId={0} panels={panels} />
    </EuiPopover>
  );
};


export const OptionsMenu = withSearch(({ addFilter, removeFilter }) => ({
  addFilter,
  removeFilter,
}))(OptionsMenuComponent);