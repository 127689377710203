import {
  EuiButtonIcon,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
} from '@elastic/eui';
import {useCallback, useState} from 'react';

import {EasyTag} from '../../common/interfaces';
import DeleteTagModal from '../modals/DeleteTagModal';
import EditTagModal from '../modals/EditTagModal';

interface TagManagementTableProps {
  tags: EasyTag[];
  onDeleteTag: (tag: EasyTag) => void;
  onEditTag: (tag: EasyTag, newLabel: string) => void;
}

const TagManagementTable: React.FC<TagManagementTableProps> = ({
  tags,
  onDeleteTag,
  onEditTag,
}) => {
  const [selectedTag, setSelectedTag] = useState<EasyTag | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editedLabel, setEditedLabel] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleEditSave = useCallback(async (): Promise<void> => {
    if (selectedTag && editedLabel.trim()) {
      setIsSaving(true);
      try {
        await onEditTag(selectedTag, editedLabel.trim());
        setShowEditModal(false);
      } finally {
        setIsSaving(false);
        setSelectedTag(null);
        setEditedLabel('');
      }
    }
  }, [selectedTag, editedLabel, onEditTag]);

  const handleDelete = useCallback(async (): Promise<void> => {
    if (selectedTag) {
      setIsSaving(true);
      try {
        await onDeleteTag(selectedTag);
        setShowDeleteModal(false);
        setSelectedTag(null);
      } finally {
        setIsSaving(false);
      }
    }
  }, [selectedTag, onDeleteTag]);

  return (
    <>
      <EuiTable>
        <EuiTableHeader>
          <EuiTableHeaderCell>Tag Name</EuiTableHeaderCell>
          <EuiTableHeaderCell>Actions</EuiTableHeaderCell>
        </EuiTableHeader>
        <EuiTableBody>
          {tags.map((tag) => (
            <EuiTableRow key={tag.id}>
              <EuiTableRowCell>{tag.label}</EuiTableRowCell>
              <EuiTableRowCell>
                <EuiButtonIcon
                  iconType="pencil"
                  color="primary"
                  onClick={() => {
                    setSelectedTag(tag);
                    setEditedLabel(tag.label);
                    setShowEditModal(true);
                  }}
                />
                <EuiButtonIcon
                  iconType="trash"
                  color="danger"
                  onClick={() => {
                    setSelectedTag(tag);
                    setShowDeleteModal(true);
                  }}
                />
              </EuiTableRowCell>
            </EuiTableRow>
          ))}
        </EuiTableBody>
      </EuiTable>

      <EditTagModal
        isVisible={showEditModal}
        tagName={editedLabel}
        isSaving={isSaving}
        onClose={() => setShowEditModal(false)}
        onSave={handleEditSave}
        onChange={setEditedLabel}
      />

      <DeleteTagModal
        isVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        isSaving={isSaving}
        label={selectedTag?.label || ''}
      />
    </>
  );
};

export default TagManagementTable;
