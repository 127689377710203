import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  useEuiTheme,
} from '@elastic/eui';
import React from 'react';
import {useTranslation} from 'react-i18next';

type FavoriteViewModalProps = {
  isDefaultMarked: boolean;
  onClose: () => void;
  onSubmit: (isDefaultMarked: boolean) => void;
};

const FavoriteViewModal: React.FC<FavoriteViewModalProps> = ({
  isDefaultMarked,
  onClose,
  onSubmit,
}) => {
  const {t} = useTranslation();
  const { colorMode } = useEuiTheme();
  
  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Modals.FavoriteView.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      {isDefaultMarked ? (
        <EuiModalBody  className={colorMode.toLowerCase()}>
          {t('Modals.FavoriteView.RemoveAsDefaultText')}
        </EuiModalBody>
      ) : (
        <EuiModalBody >{t('Modals.FavoriteView.AddAsDefaultText')}</EuiModalBody>
      )}
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          {t('Modals.FavoriteView.Cancel')}
        </EuiButtonEmpty>
        <EuiButton fill onClick={() => onSubmit(isDefaultMarked)}>
          {t('Modals.FavoriteView.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default FavoriteViewModal;
