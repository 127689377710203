import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface DeleteTagModalProps {
  isVisible: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
  isSaving: boolean;
  label: string;
}

const DeleteTagModal: FC<DeleteTagModalProps> = ({
  isVisible,
  onClose,
  onDelete,
  isSaving,
  label,
}) => {
  const {t} = useTranslation();

  const { colorMode } = useEuiTheme();
  if (!isVisible) return null;

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Configuration.TagsManager.DeleteModal.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody  className={colorMode.toLowerCase()}>
        <EuiText>{t('Configuration.TagsManager.DeleteModal.Message')}</EuiText>
        <EuiSpacer size="l" />
        <EuiText>
          Tag: <b>{label}</b>
        </EuiText>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} isDisabled={isSaving}>
          {t('Configuration.TagsManager.Buttons.Cancel')}
        </EuiButtonEmpty>
        <EuiButton color="danger" onClick={onDelete} isLoading={isSaving}>
          {t('Configuration.TagsManager.Buttons.Confirm')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default DeleteTagModal;
