import { EuiPageTemplate, EuiProvider, EuiThemeProvider } from '@elastic/eui';
import { FluentProvider, teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme, Theme, tokens } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import config from '../common/config';
import { ToastProvider } from '../contexts/ToastContext';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { TeamsFxContext } from './TeamsFxContext';

// Importieren Sie die ElasticUI Themes
import '@elastic/eui/dist/eui_theme_light.css';
import '@elastic/eui/dist/eui_theme_dark.css';

export const App = () => {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        app.notifySuccess();
      });
  }, [loading]);

  const fluentTheme =
    themeString === "dark"
      ? { ...teamsDarkTheme, fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif" }
      : themeString === "contrast"
        ? { ...teamsHighContrastTheme, fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif" }
        : { ...teamsLightTheme, fontFamilyBase: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif" };

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <EuiThemeProvider colorMode={themeString === 'dark' ? 'dark' : 'light'} >
        <FluentProvider
          className={themeString === 'dark' ? 'dark' : 'light'}
          theme={fluentTheme}
          style={{ fontFamily: tokens.fontFamilyBase, minHeight: '100%', background: "linear-gradient(22deg,var(--modal-background-color)0,rgba(255,255,255,.2) 100%);" }}
        >
          <ToastProvider>
            {!loading && (
              <BrowserRouter>
                <DefaultLayout />
              </BrowserRouter>
            )}
          </ToastProvider>
        </FluentProvider>
      </EuiThemeProvider>
    </TeamsFxContext.Provider>
  );
};