// import '@elastic/eui/dist/eui_theme_light.css';
import {createRoot} from 'react-dom/client';
import {App} from './components/App';
import './index.scss';

import {EuiProvider, EuiThemeProvider} from '@elastic/eui';

import './i18n';

import i18n from './i18n';

const overrides = {
  colors: {
    LIGHT: {
      primary: '#402EB3',
    },
  },
  font: {
    family: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif",
  },
};

i18n.changeLanguage(navigator.language);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <EuiProvider>
      <App />
  </EuiProvider>
);
