import {EuiFlexGroup, EuiFlexItem, EuiTitle} from '@elastic/eui';
import {ResultViewProps} from '@elastic/react-search-ui-views';
import {Spinner} from '@fluentui/react-components';
import {useGraphWithCredential} from '@microsoft/teamsfx-react';
import {useState} from 'react';

import {TeamsUserCredentialContext} from '../../auth/singletonContext';
import config from '../../common/config';
import {DetailsModal} from '../DetailsModal';
import {EasyUser} from '../EasyUser';
import {QuickActions} from '../QuickActions';

interface EasyPresenceViewProps {
  resultView: ResultViewProps;
}

export const EasyPresenceView: React.FC<EasyPresenceViewProps> = ({
  resultView,
}) => {
  const credential = TeamsUserCredentialContext.getInstance().getCredential();

  if (!credential) {
    throw new Error('TeamsFx SDK is not initialized.');
  }

  const [isDetailModalVisible, setIsDetailModalVisible] =
    useState<boolean>(false);

  const handleDetailModalClose = () => {
    setIsDetailModalVisible(false);
  };

  const handleDetailModalOpen = () => {
    setIsDetailModalVisible(true);
  };

  const {data} = useGraphWithCredential(
    async (graph) => {
      let profile = null,
        presence = null,
        photoUrl = '';

      try {
        // Batch request for profile and presence
        const batchRequest = {
          requests: [
            {
              id: '1',
              method: 'GET',
              url: `/users/${resultView.result.id?.raw}`,
            },
            {
              id: '2',
              method: 'GET',
              url: `/users/${resultView.result.id?.raw}/presence`,
            },
          ],
        };

        const batchResponse = await graph.api('/$batch').post(batchRequest);
        const profileResponse = batchResponse.responses.find(
          (res: any) => res.id === '1',
        );
        const presenceResponse = batchResponse.responses.find(
          (res: any) => res.id === '2',
        );

        profile = profileResponse?.body || {};
        presence = presenceResponse?.body || {};

        try {
          const photoResponse = await graph
            .api(`/users/${resultView.result.id?.raw}/photo/$value`)
            .get();
          photoUrl = URL.createObjectURL(photoResponse);
        } catch {
          photoUrl = '';
        }
      } catch (e) {
        console.error('Error in batch or photo request', e);
      }

      return {profile, presence, photoUrl};
    },
    {scope: config.easyUserScopes, credential: credential},
  );

  return (
    <>
      {resultView.result ? (
        <EuiFlexGroup
          className={
            'sui-result-presence ' +
            ' presenceView card-' +
            (data?.presence?.availability?.toLowerCase() ?? 'presenceunknown')
          }
          gutterSize="m"
        >
          <EuiFlexItem grow={false}>
            <EasyUser
              activity={data?.presence?.activity}
              presence={data?.presence?.availability}
              photo={data?.photoUrl}
              displayName={resultView.result.display_name?.raw}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="column" gutterSize="none">
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h1
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {resultView.result.display_name?.raw}
                  </h1>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <QuickActions
                  resultView={resultView}
                  handleDetailModalOpen={handleDetailModalOpen}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <>
          <Spinner size="small" />
        </>
      )}

      <DetailsModal
        resultView={resultView}
        isOpen={isDetailModalVisible}
        onClose={handleDetailModalClose}
      />
    </>
  );
};
