import {
  EuiButtonIcon,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
} from '@elastic/eui';
import {FC, useCallback, useState} from 'react';

import {SharedMailbox} from '../../common/interfaces';
import DeleteMailboxModal from '../modals/DeleteMailboxModal';

interface SharedMailboxTableProps {
  mailboxes: SharedMailbox[];
  onDeleteMailbox: (index: string) => void;
}

const SharedMailboxTable: FC<SharedMailboxTableProps> = ({
  mailboxes,
  onDeleteMailbox,
}) => {
  const [selectedMailbox, setSelectedMailbox] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleDelete = useCallback(async (): Promise<void> => {
    if (selectedMailbox !== null) {
      try {
        setIsSaving(true);
        await onDeleteMailbox(selectedMailbox);
        setShowDeleteModal(false);
        setSelectedMailbox(null);
      } finally {
        setIsSaving(false);
      }
    }
  }, [selectedMailbox, onDeleteMailbox]);

  return (
    <>
      <EuiTable>
        <EuiTableHeader>
          <EuiTableHeaderCell>Mailbox Address</EuiTableHeaderCell>
          <EuiTableHeaderCell>Actions</EuiTableHeaderCell>
        </EuiTableHeader>
        <EuiTableBody>
          {mailboxes.map((mailbox) => (
            <EuiTableRow key={mailbox.id}>
              <EuiTableRowCell>{mailbox.address}</EuiTableRowCell>
              <EuiTableRowCell>
                <EuiButtonIcon
                  iconType="trash"
                  color="danger"
                  onClick={() => {
                    setSelectedMailbox(mailbox.address);
                    setShowDeleteModal(true);
                  }}
                />
              </EuiTableRowCell>
            </EuiTableRow>
          ))}
        </EuiTableBody>
      </EuiTable>
      <DeleteMailboxModal
        isVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
        isSaving={isSaving}
        label={mailboxes[parseInt(selectedMailbox || '')]?.address}
      />
    </>
  );
};

export default SharedMailboxTable;
