import axios from 'axios';

import config from '../common/config';
import {Subscription} from '../common/interfaces';

export class SubscriptionService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith('Bearer ')) {
        this.access_token = access_token;
      } else {
        this.access_token = 'Bearer ' + access_token;
      }
    }
  }

  access_token: string | undefined;

  public async enterTurnstile(
    subscriptionId: string,
    seatRequestData: any,
  ): Promise<any> {
    try {
      const response = await axios.post(
        `${config.apiEndpoint}/subscriptions/${subscriptionId}/entry`,
        seatRequestData,
        {
          headers: {
            Authorization: `${this.access_token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error('Error in enterTurnstile:', error);
      throw error;
    }
  }

  getSubscriptionStatus(status_id: number): string {
    switch (status_id) {
      case 0:
        return 'Unknown';
      case 1:
        return 'Pending Confirmation';
      case 2:
        return 'Pending Activation';
      case 3:
        return 'Active';
      case 4:
        return 'Suspended';
      case 5:
        return 'Cancelled';
      case 6:
        return 'Purchased';
      default:
        return 'Unknown';
    }
  }

  getSubscriptionProductTitle(offer_id: string): string {
    switch (offer_id) {
      case 'easycallreport_dev-preview':
      case 'easycallreport_dev':
      case 'easycallreport-preview':
      case 'easycallreport':
      case 'EasyCallReport-Basic-CHF-Yearly':
      case 'EasyCallReport-Basic-EUR-Yearly':
      case 'EasyCallReport-Pro-CHF-Yearly':
      case 'EasyCallReport-Pro-EUR-Yearly':
      case 'EasyCallReport-Enterprise-CHF-Yearly':
      case 'EasyCallReport-Enterprise-EUR-Yearly':
      case 'Easy-Call-Report-NFR-CHF-Monthly':
      case 'Easy-Call-Report-NFR-EUR-Monthly':
        return 'Easy Call Report';
      case 'TeamsEasyCallReport-Standard-CHF-Yearly':
        return 'Easy Call Report (deprecated)';
      case 'TeamsEasyCallReport-Standard-EUR-Yearly':
        return 'Easy Call Report (deprecated)';
      default:
        return 'Unknown';
    }
  }

  getSubscriptionProductPlan(plan_id: string): string {
    switch (plan_id) {
      case 'ecr_basic':
      case 'basic':
      case 'basic_plan':
      case 'EasyCallReport-Basic-CHF-Yearly':
      case 'EasyCallReport-Basic-EUR-Yearly':
      case 'TeamsEasyCallReport-Standard-CHF-Yearly':
      case 'TeamsEasyCallReport-Standard-EUR-Yearly':
        return 'Basic';
      case 'pro':
      case 'pro_plan':
      case 'EasyCallReport-Pro-CHF-Yearly':
      case 'EasyCallReport-Pro-EUR-Yearly':
        return 'Professional';
      case 'Easy-Call-Report-NFR-CHF-Monthly':
      case 'Easy-Call-Report-NFR-EUR-Monthly':
        return 'NFR';
      case 'enterprise':
      case 'enterprise_plan':
      case 'EasyCallReport-Enterprise-CHF-Yearly':
      case 'EasyCallReport-Enterprise-EUR-Yearly':
        return 'Enterprise';
      default:
        return 'Unknown';
    }
  }

  checkDeprecatedEcr(plan_id: string): boolean {
    switch (plan_id) {
      case 'TeamsEasyCallReport-Standard-CHF-Yearly':
      case 'TeamsEasyCallReport-Standard-EUR-Yearly':
        return true;
      default:
        return false;
    }
  }

  async getSubscriptionFromToken(
    store_token: string,
  ): Promise<Subscription | null> {
    const response = await fetch(
      config.apiEndpoint +
        '/subscriptions/landing?token=' +
        encodeURIComponent(store_token),
      {
        method: 'GET',
      },
    ).catch(console.log);

    if (response) {
      const res = await response.json();

      return (res as Subscription) ?? null;
    }

    return null;
  }

  async postNewSubscription(
    store_token: string,
    subscription: Subscription,
  ): Promise<Subscription | null> {
    const response = await fetch(config.apiEndpoint + '/subscriptions', {
      method: 'POST',
      headers: {
        Authorization: store_token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(subscription),
    }).catch(console.log);

    if (response) {
      const res = await response.json();

      return (res as Subscription) ?? null;
    }

    return null;
  }

  async getSubscriptions(): Promise<Subscription[] | null> {
    try {
      const response = await axios.get(config.apiEndpoint + '/subscriptions', {
        headers: {
          Authorization: this.access_token,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getSubscription(subscriptionId: string): Promise<Subscription | null> {
    try {
      const response = await axios.get(
        config.apiEndpoint + '/subscriptions/' + subscriptionId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  // Checks for a valid license and return seat count
  // async getEasyCallReportSeatCount(subscriptions?: Subscription[]): Promise<number> {
  //     let seatCount:number = 0;

  //     if(subscriptions === undefined && subscriptions !== null) {
  //         subscriptions = await this.getSubscriptions();
  //     }

  //     subscriptions.forEach((subscription) => {
  //         if(subscription.offer_id.includes("easycallreport") === true) {
  //             seatCount = seatCount + subscription.seatQuantity;
  //         }
  //     });

  //     seatCount = seatCount * 5; // Multiply with 5, because we offer 5 call queues per seat

  //     return seatCount;
  // }

  getEasyCallReportSeatCount(subscriptions: Subscription[]): number {
    let seatCount = 0;

    subscriptions.forEach((subscription) => {
      if (subscription.state === 3) {
        if (subscription.offer_id.includes('easycallreport') === true) {
          seatCount = seatCount + subscription.seatQuantity! * 5; // Multiply with 5 for MS licenses, because we offer 5 call queues per seat
        } else if (subscription.offer_id.includes('EasyCallReport') === true) {
          seatCount = seatCount + subscription.seatQuantity!;
        }
      }
    });

    return seatCount;
  }

  IsEasyCallReportProLicensed(subscriptions: Subscription[]): boolean {
    try {
      let isLicensed = false;

      subscriptions.forEach((subscription) => {
        if (subscription.state === 3) {
          if (
            subscription.offer_id.includes('easycallreport') === true ||
            subscription.offer_id.includes('EasyCallReport')
          ) {
            if (
              subscription.plan_id.includes('pro') ||
              subscription.plan_id.includes('enterprise') ||
              subscription.plan_id.includes('EasyCallReport-Pro') ||
              subscription.plan_id.includes('EasyCallReport-Enterprise') ||
              subscription.plan_id.includes('EasyCallReport-NFR')
            ) {
              isLicensed = true;
            }
          }
        }
      });

      return isLicensed;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  IsEasyCallReportLicensed(subscriptions: Subscription[]): boolean {
    try {
      let isLicensed = false;

      subscriptions.forEach((subscription) => {
        if (subscription.state === 3) {
          if (
            subscription.offer_id.includes('easycallreport') === true ||
            subscription.offer_id.includes('EasyCallReport')
          ) {
            isLicensed = true;
          }
        }
      });

      return isLicensed;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
