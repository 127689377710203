import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiToolTip,
} from '@elastic/eui';
import {withSearch} from '@elastic/react-search-ui';
import {
  Filter,
  FilterType,
  FilterValue,
  SortDirection,
  SortOption,
} from '@elastic/search-ui';
import {Toast, ToastBody, ToastTitle} from '@fluentui/react-components';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';

import { Guid, toPascalCase } from '../../common/helpers';
import { PersonalView, UserConfiguration } from '../../common/interfaces';
import { useEasyPageOptionsProvider } from '../../contexts/EasyPageOptionsContext';
import { useEasySearchProvider } from '../../contexts/EasySearchContext';
import { useToast } from '../../contexts/ToastContext';
import { ConfigurationService } from '../../services/ConfigurationService';
import { EasyContext } from '../EasyContext';
import { SaveViewPopover } from '../SaveViewPopover';
import { EasyViewDropdown } from './EasyViewDropdown';

export const applyFilters = (
  filters: Filter[],
  addFilter: (field: string, value: FilterValue, type?: FilterType) => void,
) => {
  filters.forEach((filter) => {
    filter.values.forEach((value) => {
      addFilter(filter.field, value, filter.type);
    });
  });
};

interface EasyCustomViewProps {
  searchTerm?: string;
  filters?: Filter[];
  setSearchTerm?: (searchTerm: string) => void;
  setSort: (sort: SortOption[] | string, sortDirection: SortDirection) => void;
  addFilter?: (field: string, value: FilterValue, type?: FilterType) => void;
  clearFilters?: () => void;
  sortList?: SortOption[];
  wasSearched?: boolean;
}

const EasyCustomView: React.FC<EasyCustomViewProps> = memo(
  ({
    searchTerm = '',
    filters = [],
    setSearchTerm = () => {},
    addFilter = () => {},
    setSort = () => {},
    clearFilters = () => {},
    wasSearched = false,
    sortList,
  }) => {
    const customViewObject = {
      searchTerm,
      filters,
      sort: sortList,
    };

    const {t} = useTranslation();
    const {accessToken, userId, userConfig, dispatch, tenantConfig} =
      useContext(EasyContext);
    const {selectedView, setSelectedView} = useEasySearchProvider();
    const {pageOptions, setPageOptions, onResetPageOptions} =
      useEasyPageOptionsProvider();

    const configurationService = new ConfigurationService(accessToken);

    const [, setIsModalVisible] = useState(false);
    const handleCloseModal = () => setIsModalVisible(false);

    const [views, setViews] = useState<Array<PersonalView>>([]);

    const {dispatchToast} = useToast();

    const notify = useCallback(
      (toastTitle: string, toastBody: string) => {
        dispatchToast(
          <Toast>
            <ToastTitle>{toastTitle}</ToastTitle>
            <ToastBody>{toastBody}</ToastBody>
          </Toast>,
          {timeout: 3000, intent: 'success'},
        );
      },
      [dispatchToast],
    );

    const sortedViews = useMemo(() => {
      return [...views].sort((a, b) => a.label.localeCompare(b.label));
    }, [views]);

    const sortedOrgViews = useMemo(() => {
      return [...(tenantConfig?.orgViews || [])].sort((a, b) =>
        a.label.localeCompare(b.label),
      );
    }, [tenantConfig]);

    const getUpdatedUserConfig = async () => {
      const updatedUserConfig =
        await configurationService.getUserConfiguration(userId);

      dispatch({type: 'SET_USER_CONFIG', payload: updatedUserConfig});
      return updatedUserConfig;
    };

    useEffect(() => {
      // For an unknown reason, the keys of UserConfiguration gets loercased 
      const config: UserConfiguration = toPascalCase(userConfig);

      if (config && config.Views) {
        setViews(config.Views);
      } else {
        setViews([]);
      }
    }, [userConfig]);

    useEffect(() => {
      if (selectedView?.searchTemplate) {
        try {
          const parsedTemplate = JSON.parse(selectedView.searchTemplate);
          setSearchTerm(parsedTemplate.searchTerm || '');
          clearFilters();

          if (parsedTemplate.filters) {
            applyFilters(parsedTemplate.filters, addFilter);
          }

          console.log('Set Sorting', parsedTemplate.sort);

          if (parsedTemplate.sort && parsedTemplate.sort.length > 0) {
            setSort(parsedTemplate.sort, ''); // Pass the array of SortOptions
          } else {
            setSort('', ''); // Revert to relevance
          }
        } catch (e) {
          console.error('Error parsing search template:', e);
        }
      }
      if (selectedView?.pageOptions) {
        setPageOptions(selectedView.pageOptions);
      }
    }, [selectedView]);

    const hasUnsavedChanges = useMemo(() => {
      console.log('Sorting', sortList);
      if (!selectedView) return false;

      try {
        const currentState = {
          searchTerm,
          filters,
          pageOptions,
          sort: sortList || [],
        };

        const viewState = {
          searchTerm:
            JSON.parse(selectedView.searchTemplate || '{}').searchTerm || '',
          filters:
            JSON.parse(selectedView.searchTemplate || '{}').filters || [],
          pageOptions: selectedView.pageOptions,
          sort: JSON.parse(selectedView.searchTemplate || '{}').sort || [],
        };

        if (currentState.searchTerm !== viewState.searchTerm) return true;

        if (currentState.filters.length !== viewState.filters.length)
          return true;

        if (
          JSON.stringify(currentState.pageOptions) !==
          JSON.stringify(viewState.pageOptions)
        )
          return true;

        if (
          JSON.stringify(currentState.sort) !== JSON.stringify(viewState.sort)
        )
          return true;

        const hasFilterChanges = currentState.filters.some((filter) => {
          const matchingFilter = viewState.filters.find(
            (f: Filter) => f.field === filter.field,
          );
          if (!matchingFilter) return true;
          return (
            JSON.stringify(filter.values) !==
            JSON.stringify(matchingFilter.values)
          );
        });

        return hasFilterChanges;
      } catch (e) {
        console.error('Error checking for unsaved changes:', e);
        return false;
      }
    }, [selectedView, searchTerm, filters, pageOptions, sortList]);

    const handleUpdateView = async () => {
      if (!selectedView) return;

      const updatedView = {
        ...selectedView,
        searchTemplate: JSON.stringify({
          searchTerm,
          filters,
          sort: sortList,
        }),
        pageOptions: pageOptions,
      };

      try {
        await configurationService.updatePersonalView(userId, updatedView);

        const updatedUserConfig = await getUpdatedUserConfig();
        setViews(updatedUserConfig!.Views);
        setSelectedView(updatedView);

        notify(
          t('Notifications.Titles.View.Updated'),
          t('Notifications.Contents.View.Updated', {
            label: updatedView.label,
          }),
        );
      } catch (error) {
        console.error('Error updating view:', error);
      }
    };

    const handleSaveView = async (viewName: string) => {
      const personalView: PersonalView = {
        id: Guid(),
        label: viewName,
        searchTemplate: JSON.stringify(customViewObject),
        pageOptions: pageOptions,
      };
      try {
        await configurationService.postPersonalView(userId, personalView);

        const updatedUserConfig =
          await configurationService.getUserConfiguration(userId);

        dispatch({type: 'SET_USER_CONFIG', payload: updatedUserConfig});

        setViews(updatedUserConfig!.Views);

        setSelectedView(personalView);

        notify(
          t('Notifications.Titles.View.Created'),
          t('Notifications.Contents.View.Created', {
            label: personalView.label,
          }),
        );

        handleCloseModal();
      } catch (error) {
        console.error('Error saving view:', error);
      }
    };

    const handleEditView = async (view: PersonalView, newLabel: string) => {
      const updatedView = {
        ...view,
        label: newLabel,
      };
      try {
        await configurationService.updatePersonalView(userId, updatedView);

        const updatedUserConfig = await getUpdatedUserConfig();

        setViews(updatedUserConfig!.Views);

        setSelectedView(updatedView);

        notify(
          t('Notifications.Titles.View.Updated'),
          t('Notifications.Contents.View.Updated', {
            label: updatedView.label,
          }),
        );
      } catch (error) {
        console.error('Error updating view:', error);
      }
    };

    const handleDeleteView = async (personalView: PersonalView) => {
      try {
        await configurationService.deletePersonalView(userId, personalView.id);

        notify(
          t('Notifications.Titles.View.Deleted'),
          t('Notifications.Contents.View.Deleted', {
            label: personalView.label,
          }),
        );

        const updatedUserConfig = await getUpdatedUserConfig();

        setViews(updatedUserConfig!.Views);

        setSelectedView(undefined);
      } catch (error) {
        console.error('Error deleting view:', error);
      }
    };

    const handleFavoriteView = async (id: string, label: string) => {
      try {
        await configurationService.updateDefaultView(userId, id);

        var updatedUserConfig = await getUpdatedUserConfig();

        setViews(updatedUserConfig!.Views);

        id
          ? notify(
              t('Notifications.Titles.View.FavoriteAdded'),
              t('Notifications.Contents.View.FavoriteAdded', {
                label: label,
              }),
            )
          : notify(
              t('Notifications.Titles.View.FavoriteRemoved'),
              t('Notifications.Contents.View.FavoriteRemoved', {
                label: label,
              }),
            );
      } catch (error) {
        console.error('Error updating default view:', error);
      }
    };

    const handleReset = useCallback(async () => {
      try {
        setSelectedView(undefined);

        setSearchTerm('');
        setSort('', '');
        await clearFilters();

        onResetPageOptions();
      } catch (error) {
        console.error('Error during reset:', error);
      }
    }, [
      setSelectedView,
      setSearchTerm,
      clearFilters,
      onResetPageOptions,
      setSort,
    ]);

    return (
      <>
        <EuiFlexGroup style={{gap: '.5rem'}} direction="row">
          <EuiFlexItem grow={false}>
            <EasyViewDropdown
              views={sortedViews}
              orgViews={sortedOrgViews}
              defaultView={userConfig?.DefaultView || ''}
              deleteView={handleDeleteView}
              editView={handleEditView}
              reset={handleReset}
              setViews={setViews}
              changeDefaultView={handleFavoriteView}
            />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiToolTip position="top" content={<p>{t('Button.Reset')}</p>}>
              <EuiButtonEmpty
                onClick={handleReset}
                iconType="filterExclude"
                disabled={selectedView === undefined && !wasSearched}
              />
            </EuiToolTip>
          </EuiFlexItem>

          <EuiFlexItem grow={false}>
            <EuiToolTip position="top" content={<p>{t('Button.SaveView')}</p>}>
              <SaveViewPopover
                selectedView={selectedView}
                hasUnsavedChanges={hasUnsavedChanges}
                isDisabled={
                  !wasSearched ||
                  !(searchTerm || filters.length > 0 || pageOptions.groupedBy)
                }
                onSaveNew={handleSaveView}
                onUpdate={handleUpdateView}
                wasSearched={wasSearched}
              />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
  },
);

export default withSearch<
  EasyCustomViewProps,
  {
    searchTerm?: string;
    filters?: Filter[];
    setSearchTerm: (searchTerm: string) => void;
    addFilter: (field: string, value: FilterValue, type?: FilterType) => void;
    clearFilters: () => void;
    wasSearched: boolean;
    sortList?: SortOption[];
    setSort: (
      sort: SortOption[] | string,
      sortDirection: SortDirection,
    ) => void;
  }
>(
  ({
    searchTerm,
    filters,
    setSearchTerm,
    addFilter,
    clearFilters,
    wasSearched,
    sortList,
    setSort,
  }) => ({
    searchTerm,
    filters,
    setSearchTerm,
    addFilter,
    clearFilters,
    wasSearched,
    sortList,
    setSort,
  }),
)(EasyCustomView);
