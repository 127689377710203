import {ResultViewProps} from '@elastic/react-search-ui-views';

import {UserConfiguration} from '../common/interfaces';
import {useEasyPageOptionsProvider} from '../contexts/EasyPageOptionsContext';
import {EasyPresenceView} from './views/EasyPresenceView';
import {EasyStandardView} from './views/EasyStandardView';

interface EasyDirectoryResultViewProps {
  resultView: ResultViewProps;
  config: UserConfiguration;
}

export const EasyDirectoryResultView: React.FC<
  EasyDirectoryResultViewProps
> = ({resultView, config}) => {
  const {pageOptions} = useEasyPageOptionsProvider();

  return (
    <>
      {pageOptions.presenceView === false && (
        <EasyStandardView resultView={resultView} />
      )}

      {pageOptions.presenceView === true && (
        <EasyPresenceView resultView={resultView} />
      )}
    </>
  );
};
