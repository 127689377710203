import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {PageOptions} from '../common/interfaces';

export type EasyPageOptionsContextType = {
  pageOptions: PageOptions;
  setPageOptions: Dispatch<SetStateAction<PageOptions>>;
  onResetPageOptions: () => void;
};

const EasyPageOptionsContext = createContext<
  EasyPageOptionsContextType | undefined
>(undefined);

export const EasyPageOptionsProvider = ({children}: {children: ReactNode}) => {
  const [pageOptions, setPageOptions] = useState<PageOptions>({
    presenceView: false,
    groupedBy: undefined,
    fullScreen: false,
  });

  const handleResetPageOptions = useCallback(() => {
    setPageOptions({
      presenceView: false,
      groupedBy: undefined,
      fullScreen: false,
    });
  }, []);

  const value = useMemo(() => {
    return {
      pageOptions,
      setPageOptions,
      onResetPageOptions: handleResetPageOptions,
    };
  }, [pageOptions, handleResetPageOptions]);

  return (
    <EasyPageOptionsContext.Provider value={value}>
      {children}
    </EasyPageOptionsContext.Provider>
  );
};

export const useEasyPageOptionsProvider = (): EasyPageOptionsContextType => {
  const context = useContext(EasyPageOptionsContext);
  if (!context) {
    throw new Error(
      'useEasyPageOptionsProvider must be used within an EasyPageOptionsProvider',
    );
  }
  return context;
};
