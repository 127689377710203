import {EuiBadge, EuiFlexGroup, EuiFlexItem} from '@elastic/eui';
import {Button, Spinner} from '@fluentui/react-components';
import React, {useRef, useState} from 'react';

import {EasyTag} from '../../common/interfaces';
import SearchableTagDropdown from './SearchableDropdown';

interface TagManagementProps {
  tags: string[];
  orgTags: Array<{id: string; label: string}>;
  onAddTag: (tag: string) => Promise<void>;
  isAddingTag: boolean;
}

export const TagManagementSection: React.FC<TagManagementProps> = ({
  tags,
  orgTags,
  onAddTag,
  isAddingTag,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [addTagEnabled, setAddTagEnabled] = useState(false);
  const [selectedTag, setSelectedTag] = useState<{
    id: string;
    label: string;
  } | null>(null);

  const availableTags = orgTags.filter((tag) => !tags.includes(tag.label));

  const handleAddTag = async () => {
    if (selectedTag) {
      await onAddTag(selectedTag.label);
      setSelectedTag(null);
      setAddTagEnabled(false);
    }
  };

  return (
    <div ref={containerRef}>
      <EuiFlexGroup wrap gutterSize="xs">
        {addTagEnabled ? (
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={true}>
              <SearchableTagDropdown
                options={availableTags}
                placeholder="Select a tag"
                noResultsText="No matching tags found"
                onSelect={setSelectedTag}
                selectedOption={selectedTag}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button
                disabled={!selectedTag || isAddingTag}
                onClick={handleAddTag}
                icon={isAddingTag ? <Spinner size="tiny" /> : undefined}
              >
                Add
              </Button>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiBadge
            iconSide="left"
            iconType="plus"
            onClick={() => setAddTagEnabled(true)}
            onClickAriaLabel="Add new tag"
            iconOnClick={() => setAddTagEnabled(true)}
            iconOnClickAriaLabel="Add new tag"
          >
            Add Tag
          </EuiBadge>
        )}
      </EuiFlexGroup>
    </div>
  );
};
