import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui';
import { Link, makeStyles, Text, tokens } from '@fluentui/react-components';
import {
  Building20Regular,
  BuildingRegular,
  Mail20Regular,
  Map20Regular,
  Patient20Regular,
  PeopleCommunity20Regular,
  Phone20Regular,
} from '@fluentui/react-icons';
import { ReactNode, useEffect, useState } from 'react';

interface CardLineProps {
  children: ReactNode;
  iconType?: string;
  copyEnabled?: boolean;
  onCopy?: () => void;
  onClick?: () => void;
  clickEnabled?: boolean;
}

const useClasses = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
  },
});

export const EasyCardLine: React.FC<CardLineProps> = ({
  children,
  iconType,
  onCopy = () => { },
  copyEnabled = false,
  onClick = () => { },
  clickEnabled = false,
}) => {
  const [copyClicked, onCopyClicked] = useState(false);
  const classes = useClasses();

  useEffect(() => {
    if (copyClicked) {
      const timer = setTimeout(() => onCopyClicked(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copyClicked]);

  const renderIcon = () => {
    switch (iconType) {
      case 'company':
        return <Building20Regular className={classes.iconColor} />;
      case 'email':
        return <Mail20Regular className={classes.iconColor} />;
      case 'phone':
        return <Building20Regular className={classes.iconColor} />;
      case 'mobile':
        return <Phone20Regular className={classes.iconColor} />;
      case 'map':
        return <Map20Regular className={classes.iconColor} />;
      case 'position':
        return <Patient20Regular className={classes.iconColor} />;
      case 'department':
        return <PeopleCommunity20Regular className={classes.iconColor} />;
      default:
        return <EuiIcon type={iconType!} className={classes.iconColor} />;
    }
  };

  return (
    <EuiFlexGroup
      alignItems="center"
      responsive={false}
      gutterSize="s"
      style={{ maxWidth: '100%' }}
    >
      {iconType && <EuiFlexItem grow={false}>{renderIcon()}</EuiFlexItem>}
      <EuiFlexItem style={{ overflow: 'hidden' }}>
        {clickEnabled ? (
          <Link
            title={children as string}
            appearance="subtle"
            onClick={clickEnabled ? onClick : undefined}
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {children}
          </Link>) : (
          <EuiText style={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            color: 'var(--colorNeutralForeground2)'
          }}>
            {children}
          </EuiText>
        )}
      </EuiFlexItem>

      {copyEnabled && (
        <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
          <EuiIcon
            className={classes.iconColor}
            type={copyClicked ? 'check' : 'copy'}
            onClick={() => {
              onCopy();
              onCopyClicked(true);
            }}
            style={{ cursor: 'pointer' }}
          />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
