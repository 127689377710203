import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  useEuiTheme,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface AddTagModalProps {
  isVisible: boolean;
  isLoading: boolean;
  tag: string;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddTagModal: FC<AddTagModalProps> = ({
  isVisible,
  isLoading,
  tag,
  onClose,
  onSubmit,
  onChange,
}) => {
  const {t} = useTranslation();
  const { colorMode } = useEuiTheme();

  if (!isVisible) return null;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    await onSubmit(e);
    // Note: We don't need to call onClose here as it's now handled in the parent
  };

  return (
    <EuiModal aria-labelledby="Add Tag" onClose={onClose}>
      <EuiForm component="form" onSubmit={handleSubmit}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            {t('Configuration.TagsManager.AddButton')}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody className={colorMode.toLowerCase()}>
          <EuiSpacer size="m" />
          <EuiFieldText
            placeholder={t('Configuration.TagsManager.Form.Placeholder')}
            aria-label={t('Configuration.TagsManager.Form.Placeholder')}
            value={tag}
            onChange={onChange}
            data-testid="tag-input"
          />
          <EuiSpacer size="m" />
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButton onClick={onClose} color="text">
            {t('Configuration.TagsManager.Buttons.Cancel')}
          </EuiButton>

          <EuiButton
            type="submit"
            fill
            isLoading={isLoading}
            disabled={tag.length === 0}
          >
            {t('Configuration.TagsManager.Buttons.Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiForm>
    </EuiModal>
  );
};

export default AddTagModal;
