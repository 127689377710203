// DetailsModal.tsx

import {withSearch} from '@elastic/react-search-ui';
import {ResultViewProps} from '@elastic/react-search-ui-views';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
} from '@fluentui/react-components';
import {useState} from 'react';

import {EasyDetailCard} from './cards/EasyDetailCard';

interface BaseDetailsModalProps {
  resultView: ResultViewProps;
  isOpen: boolean;
  onClose: () => void;
}

interface SearchProps {
  searchTerm: string | undefined;
  setSearchTerm: (term: string) => void;
  current: number | undefined;
  setCurrent: (current: number) => void;
}

type DetailsModalProps = BaseDetailsModalProps & Partial<SearchProps>;

const DetailsModalComponent: React.FC<DetailsModalProps> = ({
  resultView,
  isOpen,
  onClose,
  searchTerm,
  setSearchTerm,
  current,
  setCurrent,
}) => {
  const [tagsChanged, setTagsChanged] = useState(false);

  const handleClose = () => {
    if (
      tagsChanged &&
      setSearchTerm &&
      setCurrent &&
      searchTerm !== undefined
    ) {
      // Reset to first page and re-execute current search
      setCurrent(1);
      setSearchTerm(searchTerm);
    }
    setTagsChanged(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(_, {open}) => !open && handleClose()}
      modalType="modal"
    >
      <DialogSurface>
        <DialogBody>
          <DialogContent>
            <EasyDetailCard
              resultView={resultView}
              onTagsChange={() => setTagsChanged(true)}
            />
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export const DetailsModal = withSearch<BaseDetailsModalProps, SearchProps>(
  ({searchTerm, setSearchTerm, current, setCurrent}) => ({
    searchTerm,
    setSearchTerm,
    current,
    setCurrent,
  }),
)(DetailsModalComponent);
