import {createContext} from 'react';

import {EasyState} from '../common/interfaces';

export const EasyContext = createContext<EasyState>({
  accessToken: '',
  isLoaded: false,
  userDisplayName: '',
  userId: '',
  tenantId: '',
  userConfig: undefined,
  tenantConfig: undefined,
  subscriptions: [],
  isTagManager: false,
  dispatch: () => {},
  isSignedIn: false,
  isSeatAssigned: false,
  seatAssignmentError: '',
});
