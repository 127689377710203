import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiSpacer,
} from '@elastic/eui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SearchService } from '../services/SearchService';
import { EasyContext } from './EasyContext';
import { Facets, PersonalView } from '../common/interfaces';
import { useTranslation } from 'react-i18next';
import { useEasySearchProvider } from '../contexts/EasySearchContext';

export function WelcomeCards() {
  const easyContext = useContext(EasyContext);
  const { t } = useTranslation();
  const [facets, setFacets] = useState<Facets | null>();
  const { selectedView, setSelectedView } = useEasySearchProvider();

  const searchService = useMemo(
    () => new SearchService(easyContext.accessToken),
    [easyContext.accessToken],
  );

  useEffect(() => {
    searchService.getElasticContactTypes(easyContext.tenantId).then(facetResult => {
      setFacets(facetResult);
    })
  }, []);

  const handleContactTypeCardClick = (contacttypeid: string) => {
    const contactTypeView: PersonalView = {
      id: 'contacttype_view',
      label: getCardTitle(contacttypeid) + ' View',
      searchTemplate: JSON.stringify({
        searchTerm: '',
        filters: [
          {
            field: 'type',
            values: [contacttypeid],
            type: 'any',
          },
        ],
      }),
    };

    setSelectedView(contactTypeView);
  };

  const getCardTitle = (rawValue?: string): string => {
    let contactType = rawValue ?? "";

    if (contactType.toLowerCase() === "entraid") {
      return t(contactType);
    }
    else if (contactType.toLowerCase() === "personal") {
      return t("Sidenav.Personal");
    } else {
      if (contactType.toLowerCase() === "personal") {
        contactType = t("Main.Search.Personal.Title");
      } else {
        contactType = contactType.toLowerCase(); // contactType.replace(/(\w)(\w*)/g,
      }
      //function (g0, g1, g2) { return g1.toUpperCase() + g2; });

      return contactType;
    }
  }

  const getCardDescription = (rawValue?: string): string => {
    let contactType = rawValue ?? "";

    if (contactType.toLowerCase() === "entraid") {
      return t('Main.Search.EntraId.Description');
    } else if (contactType.toLowerCase() === "personal") {
      return t('Main.Search.Personal.Description');
    } else {
      return t('Main.Search.SharedMailbox.Description');
    }
  }

  const getCardImage = (rawValue?: string) => {
    let contactType = rawValue ?? "";

    if (contactType.toLowerCase() === "entraid") {
      return <EuiImage src="Wallpaper_EntraId.webp" alt="Easy Directory" />;
    } else if (contactType.toLowerCase() === "personal") {
      return <EuiImage src="Wallpaper_Personal.webp" alt="Easy Directory" />;
    } else {
      return <EuiImage src="Wallpaper_ED.webp" alt="Easy Directory" />;
    }
  }

  return (
    <>
      <EuiFlexGroup className='easy-welcome-cards' gutterSize="l" alignItems="center" justifyContent="spaceAround">
        {facets?.type[0].data.map((contactType, index) => {
          return <EuiFlexItem key={index} grow={false}>
            <EuiCard
              className="easy-welcome-card"
              textAlign="left"
              image={getCardImage(contactType.value)}
              title={getCardTitle(contactType.value)}
              description={getCardDescription(contactType.value)}
              style={{
                color: 'var(--colorNeutralForeground2)'
              }}
              onClick={() => handleContactTypeCardClick(contactType.value)} />
          </EuiFlexItem>
        })}
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <EuiFlexGroup className='easy-welcome-help-cards' gutterSize="l" alignItems="center" justifyContent="spaceAround">
        <EuiFlexItem>
          <EuiCard
            className="easy-welcome-help-card"
            title={t("Main.Search.HowTo.Title")}
            description={t("Main.Search.HowTo.Description")}
            style={{
              color: 'var(--colorNeutralForeground2)'
            }}
            onClick={() => window.open('https://docs.easydirectory.app', '_blank')}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            className="easy-welcome-help-card"
            title={t("Main.Search.Support.Title")}
            description={t("Main.Search.Support.Description")}
            style={{
              color: 'var(--colorNeutralForeground2)'
            }}
            onClick={() => window.open('https://docs.easydirectory.app/troubleshooting/support', '_blank')}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}
